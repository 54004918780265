<script context="module" lang="ts">
	const reset = writable(false);

	export const resetCaptcha = () => {
		window.hcaptcha?.reset();
		reset.set(true);
	};
</script>

<script lang="ts">
	import { writable } from 'svelte/store';

	import { PUBLIC_HCAPTCHA_SITEKEY } from '$env/static/public';

	export let token = '';

	let widgetID = '';
	const captcha = (node: HTMLElement) => {
		widgetID = window.hcaptcha?.render(node, {
			sitekey: PUBLIC_HCAPTCHA_SITEKEY,
			theme: 'dark',
			size: 'normal',
			'expired-callback': () => {
				token = '';
			},
			callback: (t) => {
				reset.set(false);
				token = t;
			},
		});

		const unsubscribe = reset.subscribe((update) => {
			if (update) token = '';
		});

		return {
			destroy: () => {
				reset.set(false);
				unsubscribe();
			},
		};
	};
</script>

<div use:captcha class="flex w-full justify-center" />
